import * as Sentry from '@sentry/node';
import * as React from 'react';
// import { GenericObject } from "../data/types/GenericObject";
import ErrorDebug from './ErrorDebug';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import { trackAction } from '../amplitude/amplitudeBrowserClient';

type Props = {
	error: Error;
	context?: any;
};

/**
 * Default error layout, used by DefaultLayout to display errors instead of the page's content, when an error is caught
 *
 * Displays a report dialog modal allowing end-users to provide a manual feedback about what happened.
 * You may want to customise this component to display different error messages to the end users, based on statusCode or other information.
 */
const DefaultErrorLayout: React.FunctionComponent<Props> = (
	props
): JSX.Element => {
	const { error, context } = props;
	const errorEventId = Sentry.captureException(error);

	return (
		<div>
			<div className={'title'}>
				<h1>Service currently unavailable</h1>
				<pre>Error 500.</pre>
			</div>

			<div>
				<p>
					Try to refresh the page. Please contact our support below if the issue
					persists.
				</p>
				<button
					color={'primary'}
					onClick={(): void => {
						trackAction({
							action: AMPLITUDE_ACTIONS.CLICK,
							event: AMPLITUDE_EVENTS.SUPPORT_CONTACT,
						})
						// @ts-ignore XXX showReportDialog is not recognised by TS (due to the webpack trick that replaces @sentry/node), but it works just fine
						Sentry.showReportDialog({ eventId: errorEventId })
					}}
				>
					Contact support
				</button>
			</div>

			{process.env.NEXT_PUBLIC_APP_STAGE !== 'production' && (
				<ErrorDebug error={error} context={context} />
			)}
		</div>
	);
};

export default DefaultErrorLayout;
