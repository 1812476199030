import { initializeApp } from 'firebase/app';
import {
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	setPersistence,
	browserSessionPersistence,
	onAuthStateChanged,
	sendPasswordResetEmail,
	signOut,
} from 'firebase/auth';
import { firebaseConfig, firebaseDevConfig } from './config';
import { createLogger } from '@/modules/logging/logger';

const logger = createLogger({
	fileLabel: `src/modules/firebase`,
});

try {
	initializeApp(firebaseConfig);
} catch (e) {
	logger.info(e);
}
const firebaseAuth = getAuth();
class FirebaseHelper {
	EMAIL = 'email';
	FACEBOOK = 'facebook';
	GOOGLE = 'google';
	GITHUB = 'github';
	TWITTER = 'twitter';
	constructor() {
		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.isAuthenticated = this.isAuthenticated.bind(this);
	}

	signup(info) {
		return createUserWithEmailAndPassword(
			firebaseAuth,
			info.email,
			info.password
		).catch((error) => {
			// Handle Errors here.
			logger.error(error);
			var errorMessage = error.message;
			throw new Error(errorMessage);
		});
	}
	login(provider, info) {
		return signInWithEmailAndPassword(firebaseAuth, info.email, info.password);
		// case this.FACEBOOK:
		// 	return firebaseAuth().FacebookAuthProvider();
		// case this.GOOGLE:
		// 	return firebaseAuth().GoogleAuthProvider();
		// case this.GITHUB:
		// 	return firebaseAuth().GithubAuthProvider();
		// case this.TWITTER:
		// 	return firebaseAuth().TwitterAuthProvider();
	}
	logout() {
		return signOut();
	}
	updateUser() {
		var user = currentUser;

		user
			.updateProfile({
				displayName: 'Jane Q. User',
				photoURL: 'https://example.com/jane-q-user/profile.jpg',
			})
			.then(function () {
				// Update successful.
			})
			.catch(function (error) {
				// An error happened.
				logger.info('An error happened.', error);
			});
	}
	isAuthenticated() {
		onAuthStateChanged((user) => {
			return user ? true : false;
		});
	}
	getUser() {
		return firebaseAuth.currentUser;
	}

	async resetPassword(email) {
		return await sendPasswordResetEmail(firebaseAuth,email);
	}

	async updatePassword(password) {
		return await firebaseAuth.currentUser.updatePassword(password);
	}
}

export default new FirebaseHelper();
