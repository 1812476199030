import { getAmplitudeInstance } from '@/modules/amplitude/amplitudeBrowserClient';
import amplitudeContext from '@/modules/amplitude/context/amplitudeContext';
import UniversalCookiesManager from '@/modules/cookiesManager/UniversalCookiesManager';
// import useCustomer from '@/modules/data/hooks/useCustomer';
// import useDataset from '@/modules/data/hooks/useDataset';
// import { Customer } from '@/modules/data/types/Customer';
import Script from 'next/script';
import { AuthProvider } from '@/modules/firebase/provider';
// import { detectLightHouse } from '@/modules/lightHouse/lighthouse';
import { createLogger } from '@/modules/logging/logger';
import {
	ClientNetworkConnectionType,
	ClientNetworkInformationSpeed,
	getClientNetworkConnectionType,
	getClientNetworkInformationSpeed,
} from '@/modules/networkInformation/networkInformation';
import { configureSentryBrowserMetadata } from '@/modules/sentry/browser';
import { configureSentryUserMetadata } from '@/modules/sentry/universal';
import initCookieConsent, {
	getUserConsent,
} from '@/modules/userConsent/cookieConsent';
import { UserConsent } from '@/modules/userConsent/types/UserConsent';
import TagManager from 'react-gtm-module';
// import { cypressContext } from '@/modules/testing/contexts/cypressContext';
// import {
// 	CYPRESS_WINDOW_NS,
// 	detectCypress,
// } from '@/modules/testing/cypress';
// import userConsentContext from '@/modules/userConsent/contexts/userConsentContext';
// import initCookieConsent, {
// 	getUserConsent,
// } from '@/modules/userConsent/cookieConsent';
// import { UserConsent } from '@/modules/userConsent/types/UserConsent';
import { UserSemiPersistentSession } from '@/modules/userSession/types/UserSemiPersistentSession';
import { userSessionContext } from '@/modules/userSession/userSessionContext';
// import { getIframeReferrer, isRunningInIframe } from '@/utils/iframe';
import { Amplitude, AmplitudeProvider } from '@amplitude/react-amplitude';
import { useTheme } from '@emotion/react';
import * as Sentry from '@sentry/node';
import { AmplitudeClient } from 'amplitude-js';
import React, { useEffect } from 'react';
import { MultiversalAppBootstrapPageProps } from '../types/MultiversalAppBootstrapPageProps';
import { MultiversalAppBootstrapProps } from '../types/MultiversalAppBootstrapProps';
import { MultiversalPageProps } from '../types/MultiversalPageProps';
import { OnlyBrowserPageProps } from '../types/OnlyBrowserPageProps';
import { useTranslation } from 'react-i18next';
import cypressContext from '@/modules/testing/contexts/cypressContext';
import { detectCypress } from '@/modules/testing/cypress';
import { detectLightHouse } from '@/modules/lightHouse/lighthouse';
import { useRouter } from 'next/router';
import { pageview } from '@/modules/gtag';
import { GA_TRACKING_ID, GTM_TRACKING_ID } from '@/constants/env';
import { helveticaDisplayNow } from 'fonts/fonts';
// import { SpotifyProvider } from '@/modules/spotify/provider';

const fileLabel = 'app/components/BrowserPageBootstrap';
const logger = createLogger({
	fileLabel,
});

export type BrowserPageBootstrapProps = MultiversalAppBootstrapProps<
	MultiversalPageProps & MultiversalAppBootstrapPageProps
>;

/**
 * Bootstraps the page, only when rendered on the browser
 *
 * @param props
 */
const BrowserPageBootstrap = (
	props: BrowserPageBootstrapProps
): JSX.Element => {
	const { Component, err, router } = props;
	// When the page is served by the browser, some browser-only properties are available
	const pageProps =
		props.pageProps as unknown as MultiversalPageProps<OnlyBrowserPageProps>;
	const {
		// customerRef,
		lang,
		locale,
	} = pageProps;
	useEffect(() => {
		const handleRouteChange = (url) => {
			pageview(url);
		};
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router.events]);
	//Inserting the tracking code
	useEffect(() => {
		TagManager.initialize({ gtmId: GTM_TRACKING_ID });
	}, []);

	const { t, i18n } = useTranslation();
	// const dataset = useDataset();
	// const customer: Customer = useCustomer();
	// const isInIframe: boolean = isRunningInIframe();
	// const iframeReferrer: string = getIframeReferrer();
	const cookiesManager: UniversalCookiesManager = new UniversalCookiesManager(); // On browser, we can access cookies directly (doesn't need req/res or page context)
	const userSession: UserSemiPersistentSession = cookiesManager.getUserData();
	const userId = userSession.id;
	const injectedPageProps: MultiversalPageProps<OnlyBrowserPageProps> = {
		...props.pageProps,
		// isInIframe,
		// iframeReferrer,
		cookiesManager,
		userSession,
	};
	const theme = useTheme();
	const isCypressRunning = detectCypress();
	const isLightHouseRunning = detectLightHouse();
	const networkSpeed: ClientNetworkInformationSpeed =
		getClientNetworkInformationSpeed();
	const networkConnectionType: ClientNetworkConnectionType =
		getClientNetworkConnectionType();

	// Configure Sentry user and track navigation through breadcrumb
	configureSentryUserMetadata(userSession);
	configureSentryBrowserMetadata(
		networkSpeed,
		networkConnectionType
		// isInIframe,
		// iframeReferrer
	);

	Sentry.addBreadcrumb({
		// See https://docs.sentry.io/enriching-error-data/breadcrumbs
		category: fileLabel,
		message: `Rendering ${fileLabel}`,
		level: Sentry.Severity.Debug,
	});

	const userConsent: UserConsent = getUserConsent();
	// const { isUserOptedOutOfAnalytics, hasUserGivenAnyCookieConsent } =
	// 	userConsent;
	const amplitudeInstance: AmplitudeClient = getAmplitudeInstance({
		// customerRef,
		// iframeReferrer,
		// isInIframe,
		// lang,
		locale,
		userId,
		userConsent,
		networkSpeed,
		networkConnectionType,
	});

	// // Init the Cookie Consent popup, which will open on the browser
	initCookieConsent({
		allowedPages: [
			// We only allow it on those pages to avoid display that boring popup on every page
			`${window.location.origin}/${locale}/demo/terms`,
			`${window.location.origin}/${locale}/demo/privacy`,
			`${window.location.origin}/${locale}/demo/built-in-features/cookies-consent`,
		],
		amplitudeInstance,
		locale,
		t,
		theme,
		userConsent,
	});
	// XXX Inject data so that Cypress can use them to run dynamic tests.
	//  Those data mustn't be sensitive. They'll be available in the DOM, no matter the stage of the app.
	//  This is needed to run E2E tests that are specific to a customer. (dynamic testing)
	// window[CYPRESS_WINDOW_NS] = {
	// 	dataset,
	// 	customer,
	// };

	// In non-production stages, bind some utilities to the browser's DOM, for ease of quick testing
	if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
		window['amplitudeInstance'] = amplitudeInstance;
		window['i18n'] = i18n;
		window['router'] = router;
		window['t'] = t;
		logger.info(`Utilities have been bound to the DOM for quick testing (only in non-production stages):
	      - amplitudeInstance
	      - i18n
	      - router
	      - t
	  `);
	}

  return (
    <AuthProvider>
      {/* <SpotifyProvider> */}
      <AmplitudeProvider
        amplitudeInstance={amplitudeInstance}
        apiKey={process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY}
        userId={userId}>
        <Script
          crossOrigin="anonymous"
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        />
        <Script
          crossOrigin="anonymous"
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtm.js?id=${GTM_TRACKING_ID}`}
        />
        <Script
          id="gtag-init"
          crossOrigin="anonymous"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />

        <Amplitude
          // DA Know that we decided to duplicate the tracking of the same properties through event props and user props
          //  This is because charts are sometimes easier to build using events props, or user users props
          //  Duplicating them facilitates the data analysis and grants more flexibility regarding how to create charts
          eventProperties={{
            app: {
              name: process.env.NEXT_PUBLIC_APP_NAME,
              release: process.env.NEXT_PUBLIC_APP_VERSION_RELEASE,
              stage: process.env.NEXT_PUBLIC_APP_STAGE,
              preset: process.env.NEXT_PUBLIC_NRN_PRESET,
            },
            page: {
              url: location.href,
              path: location.pathname,
              origin: location.origin,
              name: null, // XXX Will be set by the page (usually through its layout)
            },
            customer: {
              // ref: customerRef,
            },
            lang: lang,
            locale: locale,
            // iframe: isInIframe,
            // iframeReferrer: iframeReferrer,
            // isUserOptedOutOfAnalytics: isUserOptedOutOfAnalytics,
            // hasUserGivenAnyCookieConsent: hasUserGivenAnyCookieConsent,
            isCypressRunning,
            isLightHouseRunning,
            networkSpeed,
            networkConnectionType,
          }}
          // XXX Do not use "userProperties" here, add default user-related properties in getAmplitudeInstance instead
          //  Because "event" had priority over "user event" and will be executed before
          //  So, userProperties defined here then it will NOT be applied until the NEXT Amplitude event and this is likely gonna cause analytics issues
          // userProperties={{}}
        >
          <cypressContext.Provider value={{ isCypressRunning }}>
            <amplitudeContext.Provider value={{}}>
              <userSessionContext.Provider value={{ ...userSession }}>
                {/* <userConsentContext.Provider value={{ ...userConsent }}> */}
                <Component
                  {...injectedPageProps}
                  // @ts-ignore
                  error={err}
				  className={helveticaDisplayNow.variable}
                />
                {/* </userConsentContext.Provider> */}
              </userSessionContext.Provider>
            </amplitudeContext.Provider>
          </cypressContext.Provider>
        </Amplitude>
      </AmplitudeProvider>
      {/* </SpotifyProvider> */}
    </AuthProvider>
  );
};

export default BrowserPageBootstrap;
