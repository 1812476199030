/**
 * Event names.
 */
export enum AMPLITUDE_EVENTS { // EVENTS for actions users

  // Events for Create or Edit
  // *******************************************************
  EVENT_EDIT_PUBLISH = 'event-edit-publish',
  EVENT_EDIT_SAVE = 'event-edit-save',
  EVENT_EDIT_CANCEL = 'event-edit-cancel',

  EVENT_EDIT_SET_PUBLIC = 'event-edit-set-public',
  EVENT_EDIT_SET_PRIVATE = 'event-edit-set-private',
  EVENT_EDIT_SET_VENUE = 'event-edit-set-venue',
  EVENT_EDIT_SET_ONLINE = 'event-edit-set-online',
  EVENT_EDIT_CHOOSE_LOCATION_MAP = 'event-edit-choose-location-map',

  EVENT_EDIT_ARTIST_ENABLE = 'event-edit-artist-enable',
  EVENT_EDIT_ARTIST = 'event-edit-artist',

  EVENT_EDIT_ORGANIZER_MODAL = 'event-edit-organizer-modal',
  EVENT_EDIT_SAVE_ORGANIZER = 'event-edit-save-organizer',
  EVENT_EDIT_ADDITIONAL_CONFIRMATION = 'event-edit-additional-confirmation',

  TAB_BASIC_INFO = 'tab-basic-info',
  TAB_UPLOAD_IMAGE = 'tab-upload-image',
  TAB_CANCEL_EDIT_EVENT = 'tab-cancel-update-event',
  TAB_CHECKOUT_FIELDS = 'tab-checkout-fields',
  TAB_LINE_UP_DETAILS = 'tab-line-up-details',
  TAB_TICKET_DETAILS = 'tab-ticket-details',
  
  COUPON_ACTIVED = 'coupon-edit-active',
  EVENT_EDIT_COUPON_SELECT_TICKETS = 'event-edit-select-tickets',
  EVENT_EDIT_COUPON = 'event-edit-coupon',

  EVENT_EDIT_CLOSE_ERRORS = 'event-close-errors',
  EVENT_EDIT_OPEN_TAB = 'event-open-tab',
  
  // *******************************************************
  // Events for ManageEvents
  
  EVENT_CANCELLED = 'event-cancelled',
  EVENT_REFUND = 'event-refund',


  // *******************************************************
  // Events for Checkout User
  CHECKOUT = 'checkout',
  CHECKOUT_USER = 'checkout-user',
  CHECKOUT_APPLE_PAY = 'apple-pay-checkout',
  CHECKOUT_VIEW_TICKETS = 'checkout-view-tickets',
  CHECKOUT_TERMS_AND_CONDITIONS = 'checkout-terms-and-conditions',
  CHECKOUT_BACK = 'ticket-checkout-cancel',

  // *******************************************************
  // Events for Checkout Payment
  CHECKOUT_PAYMENT = 'checkout-payment',
  CHECKOUT_PAYMENT_BACK = 'checkout-ticket-payment-cancel',

  // *******************************************************
  // Events for Offers
  OFFER_ACCEPT_APPLY = 'offer-accept-apply',
  OFFER_CANCEL_APPLY = 'offer-cancel-apply',
  OFFER_ACCEPTED = 'offer-accepted',
  OFFER_REJECTED = 'offer-rejected',

  EVENT_URL_DUPLICATE = 'event-url-duplicated',
  
  SMS_SENT = 'sms-sent',
  SMS_PREVIEW_SENT = 'sms-preview-sent',
  RESEND_TIX = 'resend-tix',
  OPEN_TICKETS = 'open-tickets',
  EVENT_SHARE_SCAN = 'event-share-scan-link',
  EVENT_SHARE = 'event-share',
  SETUP_PAYOUT = 'setup-payout',
  EVENT_COPY_LINK = 'event-copy-link',

  CHECKOUT_TRY_AGAIN = 'checkout-try-again',

  EMAIL_SENT = 'email-sent', // QUESTION: Should we use this event for all emails sent?
  USER_FEEDBACK_SUBMITTED = 'user-feedback-submitted', // TODO
  EVENT_RATING_SUBMITTED = 'event-rating-submitted', // TODO
  EVENT_REVIEW_SUBMITTED = 'event-review-submitted', // TODO
  USER_INVITED = 'user-invited', // TODO
  
  SUPPORT_CONTACT = 'support-contact',
  OFFER_EXPIRED = 'offer-expired',
  ARTIST_FOLLOWED = 'artist-followed', // TODO
  PROMOTER_FOLLOWED = 'promoter-followed', // TODO
  VENUE_FOLLOWED = 'venue-followed', // TODO
  EVENT_DISCOVERY_SEARCH = 'event-discovery-search', // TODO
  FEATURED_EVENT_VIEWED = 'featured-event-viewed', // TODO
  TRENDING_EVENT_VIEWED = 'trending-event-viewed', // TODO
  RECOMMENDED_EVENT_VIEWED = 'recommended-event-viewed', // TODO
  EVENT_CATEGORY_FILTER_USED = 'event-category-filter-used',
  EVENT_CATEGORY_FILTER_LOCATION_USED = 'event-category-filter-location-used',
  EVENT_CATEGORY_SELECTED_LOCATION = 'event-category-selected-location',
  TICKET_PRICE_FILTER_USED = 'ticket-price-filter-used', // TODO
  GEOLOCATION_USED = 'geolocation-used', // TODO
  NOTIFICATIONS_ENABLED = 'notifications-enabled', // TODO
  NOTIFICATIONS_DISABLED = 'notifications-disabled', // TODO
  TICKET_LIST_CREATED = 'ticket-list-created', // TODO
  TICKET_LIST_SHARED = 'ticket-list-shared', // TODO
  SOCIAL_LOGIN = 'social-login', // TODO
  EVENT_IMAGE_UPLOADED = 'event-image-uploaded', // TODO
  USER_SETTINGS_UPDATED = 'user-settings-updated', // TODO
  PASSWORD_RESET_REQUESTED = 'password-reset-requested',
  PASSWORD_RESET_COMPLETED = 'password-reset-completed',
  EVENT_COMMENT_POSTED = 'event-comment-posted', // TODO
  USER_BLOCKED = 'user-blocked', // TODO
  REPORT_WEB_VITALS = 'report-web-vitals', // When the Core Web Vitals report is sent automatically on any page load
  USER_CONSENT_MANUALLY_GIVEN = 'user-consent-manually-given', // When the user makes a manual choice regarding cookies consent
  OPEN_WHAT_IS_PRESET_DOC = 'open-what-is-preset-doc', // When the user clicks on "What is a preset?" link
  OPEN_SEE_ALL_PRESETS_DOC = 'open-see-all-presets-doc', // When the user clicks on "See all presets" link
  OPEN_GITHUB_DOC = 'open-github-doc', // When the user clicks on the NRN documentation link
  OPEN_GITHUB = 'open-github', // When the user clicks on the GitHub link
  OPEN_ADMIN_SITE = 'open-admin-site', // When the user clicks on the "Go to CMS" link
  ANALYTIC_BUTTON_TEST_EVENT = 'analytics-button-test-event', // Test event for demo purpose
  API_INVOKED = 'api-invoked', // When any API is invoked
  API_LOCALE_MIDDLEWARE_INVOKED = 'api-locale-middleware-invoked', // When the "localeMiddleware" API is invoked
  API_ERROR_LOG = 'api-error', // When any API Issue is invoked,
  WAITLIST_JOIN = 'waitlist-join',
  EVENT_CODE_DISCOUNT = 'event-code-discount',

  TICKET_QUANTITY_INCREASE = 'ticket-quantity-increase',
  TICKET_QUANTITY_DECREASE = 'ticket-quantity-decrease',

  TERMS_CONDITIONS = 'terms-conditions',
  
  USER_LOGGED_OUT = 'user-logged-out',
  USER_LOG_IN = 'user-log-in',
  USER_SIGN_UP = 'user-sign-up',

  HOME_CLICK = 'home-click',
}

/**
 * Event actions.
 *
 * We use an "action" property to track the event's trigger.
 * It's especially useful when the same event can be triggered by different actions,
 * as sometimes it's easier to keep a single event with different properties. (it really depends how you want to use the data)
 *
 * Best practice: All actions must use action verb (imperative form).
 * This is a NRN internal rule (recommandation) about how to track which action led to triggering the event.
 *
 * DA Usefulness: Avoids using anonymous constants that will likely end up being duplicated.
 *  Using constants ensures strict usage with a proper definition for the analytics team and the developers.
 *  Example: Using both "remove" and "delete" could lead to misunderstanding or errors when configuring charts.
 */
export enum AMPLITUDE_ACTIONS {
  CLICK = 'click', // When an element is clicked (mouse) or tapped (screen, mobile)
  NEXT = 'next', // When the user clicks on the "Next" button
  BACK = 'back', // When the user clicks on the "Back" button
  ADD = 'add', // When an element is added on ADD button
  UPDATE = 'update', // When an element is updated on UPDATE button
  SELECT = 'select', // When an element is selected (checkbox, select input, multi choices)
  REMOVE = 'remove', // When an element is removed/delete
  OPEN = 'open', // When an element is opened
  CLOSE = 'close', // When an element is closed or canceled
  AUTO = 'auto', // When an event is triggered automatically instead of a user action,
  DECREASE = 'decrease', // When an element is decreased
  INCREASE = 'increase', // When an element is increased
}

/**
 * Pages names used within Amplitude.
 *
 * Each page within the /src/pages directory should use a different page name as "pageName".
 * This is used to track events happening within the pages, to know on which page they occurred.
 */
export enum AMPLITUDE_PAGES { // EVENTS PAGES
  DEMO_HOME_PAGE = 'demo',
  DASHBOARD_PAGE = 'dashboard/',
  CREATE_EVENT_PAGE = 'dashboard/create',
  EDIT_EVENT_PAGE = 'dashboard/edit',
  MY_EVENTS_PAGE = 'dashboard/myevents',
  DASHBOARD_PAYOUTS = 'dashboard/payouts',
  DASHBOARD_EVENT_PAGE = 'dashboard/manage/[event-id]',
  WAITLIST_EVENTS_PAGE = 'dashboard/manage/[event-id]/waitlist',
  SCAN_EVENTS_PAGE = 'dashboard/manage/[event-id]/scan',
  OFFER_EVENTS_PAGE = 'dashboard/manage/[event-id]/offers',
  ATTENDED_EVENTS_PAGE = 'dashboard/manage/[event-id]/attendees',
  ATTENDED_ORDER_EVENTS_PAGE = 'dashboard/manage/[event-id]/order/[attendee]',
  MARKETING_EVENTS_PAGE = 'dashboard/marketing',
  DASHBOARD_SETTINGS = 'dashboard/settings',
  DASHBOARD_SETTINGS_BASIC_INFO = 'dashboard/settings/basic-info',
  DASHBOARD_SETTINGS_BILLING = 'dashboard/settings/billing',

  EVENT_PAGE = 'e/[event-id]',
  TIX_PAGE = 'tix/[event-id]/order/[attendee]',

  LOGIN_PAGE = 'login',
  FORGOT_PAGE = 'forgot',
  PREVIEW_PRODUCT_PAGE = 'demo/preview-product',
  TERMS_PAGE = 'terms',
  PRIVACY_PAGE = 'demo/privacy',
  TEMPLATE_SSG_PAGE = 'template-ssg',
  TEMPLATE_SSR_PAGE = 'template-ssr',
}

/**
 * API endpoint names.
 *
 * Each API endpoint within the src/pages/api directory should use a different endpoint name.
 * This is used to track events happening within the API endpoints, to know on which endpoint they occurred.
 */

export enum AMPLITUDE_API_ENDPOINTS { // API ENDPOINTS
  STATUS = 'status',
  SEND_SMS = 'send-sms',
  RESEND_TIX = 'resend-tixs',
  PAGE_VIEWS = 'page-views',
  MAILING_LIST = 'mailing-list',
  BUY_TICKET = 'buy-ticket',
  DUPLICATE_EVENT = 'duplicate-event',
  UPDATE_ORDER = 'update-order',
  REFUND_TICKET = 'refund-ticket',
  EXPIRED_OFFER = 'expired-offer',
  LOG_IN = 'log-in',
  PROCESS_PAYOUT = 'process-payout',
  GET_USER = 'get-user',
  JOIN_WAITLIST = 'join-waitlist',
  GET_ORDER_OFFERS = 'get-order-offers',
  PAYOUT_REMINDER = 'payout-reminder',
  SEND_EMAIL = 'send-email',
  GET_TIXS = 'get-tixs',
  GET_EVENT_ORGANIZER = 'get-event-organizer',
  STRIPE_PAYMENT_INTENT = 'stripe-payment-intent',
  REFUND = 'refund-order',
  ISSUE_TICKET = 'issue-tickets',
  INVITE_ORGANIZER = 'invite-organizer',
  SIGN_UP_ORGANIZER = 'signup-organizer',
  MY_EVENTS = 'my-events',
  GET_VENUES = 'get-venues',
  SCAN_TIX = 'scan-ticketd',
  MASS_REFUND = 'mass-refund',
  GET_EVENT = 'get-event',
  GET_EVENTS = 'get-events',
  GET_ATTENDEES = 'get-attendees',
  GET_EVENT_PAGE = 'get-event-page',
  GET_USER_VENUE_DATA = 'get-user-venue-data',
  GET_COMPANY_DATA = 'get-company-data',
  DELETE_EVENT = 'delete-event',
  UPDATE_USER = 'update-user',
  UPDATE_EVENT = 'update-event',
  AUTO_REDIRECT_TO_LOCALISED_PAGE = 'autoRedirectToLocalisedPage',
  START_VERCEL_DEPLOYMENT = 'startVercelDeployment',
  ERROR = 'error',
  PREVIEW = 'preview',
  WEBHOOK_DEPLOYMENT_COMPLETED = 'deploymentCompleted',
  GET_ARTISTS = 'get-artists',
  ADD_ARTIST = 'add-artist',
  GENERATE_DESCRIPTION_WITH_AI = 'generate-description-with-ai',
}
