export const DEV = process.env.NODE_ENV !== 'production';
export const APP_DEV = process.env.APP_ENV !== 'production' 

export const GA_TRACKING_ID = 'G-792Y9JD6ZS';
export const GA_PROPERTY_ID = '310664443';
export const GTM_TRACKING_ID = 'GTM-5PPKBZG';
export const FB_TRACKING_ID = '';
export const SENTRY_TRACKING_ID = '';

export const SITE_NAME = 'TBA';
export const SITE_TITLE = 'TBA';
export const SITE_DESCRIPTION = 'Discover lit events.';
export const SITE_IMAGE = '/static/images/FILE-tba_logo-04.png';
