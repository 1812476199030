import { createContext, useEffect, useState, useContext } from 'react';
import nookies from 'nookies';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import firebaseClient from '@/modules/firebase';
import UniversalCookiesManager from '../cookiesManager/UniversalCookiesManager';
import { createLogger } from '../logging/logger';

const logger = createLogger({
  fileLabel: `src/modules/firebase/provider`,
});
const AuthContext = createContext<{
  user: User | null;
  setUser: (user: User | null) => void;
}>(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null);
  // listen for token changes
  // call setUser and write new token as a cookie
  useEffect(() => {
    const auth = getAuth();

    return onAuthStateChanged(auth, async (user) => {
      if (!user) {
        setUser(null);
        nookies.set(undefined, 'token', '', { path: '/' });
        nookies.set(undefined, 't_token', '', { path: '/' });
      } else {
        const cookiesManager: UniversalCookiesManager = new UniversalCookiesManager();
        cookiesManager.replaceUserData({
          deviceId: user.uid,
          id: user.email,
        });
        const token = await user.getIdToken();
        setUser(user);
        nookies.set(undefined, 'token', token, { path: '/' });
      }
    });
  }, [user]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebaseClient.getUser();
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);

    // clean up setInterval
    return () => clearInterval(handle);
  }, []);

  return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
}
